/**
 * Created by jurikpeter on 23/05/16.
 */
require('@powerednow/ext-sources/sdk/ext/packages/core/src/Boot.js');
require('@powerednow/ext-sources/sdk/ext/packages/core/src/env/OS.js');

(function () {
    const minVersions = {
        Safari: 9,
        Chrome: 46,
        Firefox: 42,
        Edge: 80,
    };
    const currentVersion = parseFloat(Ext.browser.version.version);
    const { location } = window;
    const queryString = location.search + location.hash;

    if (Ext.os.name !== 'ChromeOS' && (Ext.os.deviceType === 'Tablet' || Ext.os.deviceType === 'Phone' || !minVersions.hasOwnProperty(Ext.browser.name))) {
        window.location.href = `notsupported.html${queryString}`;
    } else if (currentVersion < minVersions[Ext.browser.name]) {
        window.location.href = `oldversion.html${queryString}`;
    } else {
        window.location.href = `desktop.html${queryString}`;
    }
}());
